import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { Card, Carousel, Alert } from "antd"
import { LeftCircleFilled, RightCircleFilled } from "@ant-design/icons"

const StyledParentCard = styled(Card)`
  & > div {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-direction: column;
    justify-content: center;
  }

  & > div > div:first-of-type {
    max-width: 780px;
    width: 100%;
    margin: auto;
  }

  .ant-card-head-title {
    text-align: center;
    font-size: 35px;
    white-space: normal !important;
  }
`

const headStyle = {
  textAlign: "center",
  fontSize: "35px",
}

const imageStyle = {
  margin: "auto",
}

const ProjectDescription = styled.p`
  white-space: pre-wrap;
  font-size: 16px;
  text-align: justify;
`

const StyledProjectCard = styled(Card)`
  max-width: 780px;
  width: 100%;
  margin: 15px auto 5px auto;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
`

const StyledAlert = styled(Alert)`
  max-width: 780px;
  margin: 15px 25% 0px 25%;
  text-align: center;
  @media only screen and (max-width: 780px) {
    margin: 15px 0px 0px 0px;
    width: 100%;
  }
`

const StyledCarousel = styled(Carousel)`
  width: 100%;
`

const StyledNextArrow = styled(RightCircleFilled)`
  font-size: 30px !important;
  height: 30px !important;
  width: 30px !important;
  right: 10px !important;
  color: white !important;
  z-index: 10;
  @media only screen and (max-width: 500px) {
    display: none !important;
  }
`

const StyledPrevArrow = styled(LeftCircleFilled)`
  font-size: 30px !important;
  height: 30px !important;
  width: 30px !important;
  left: 10px !important;
  color: white !important;
  z-index: 10;
  @media only screen and (max-width: 500px) {
    display: none !important;
  }
`

const DetailedProjectPage = ({ pageContext }) => {
  const project = pageContext.project
  const projectImages = pageContext.projectImages
  const mediaCreds = pageContext.mediaCreds

  return (
    <>
      <StyledParentCard>
        <StyledCarousel
          autoplay
          arrows
          nextArrow={<StyledNextArrow />}
          prevArrow={<StyledPrevArrow />}
        >
          {projectImages.map(image => (
            <GatsbyImage
              image={image.image}
              alt={image.name}
              key={image.name}
              style={imageStyle}
              onClick={() => {
                window.open(image.originalImageUrl, "__blank")
              }}
            />
          ))}
        </StyledCarousel>
        <StyledAlert
          message="You can click the image to view it in full-screen!"
          type="info"
          closable
        />
        <StyledProjectCard
          title={project.projName}
          headStyle={headStyle}
          hoverable
        >
          <ProjectDescription>{project.projDesc}</ProjectDescription>
          <p>
            Photographs By:{" "}
            {mediaCreds.map(cred => {
              return cred
            })}
          </p>
          <p>Structural Engineer: {project.structuralEngineer || `N/A`}</p>
        </StyledProjectCard>
      </StyledParentCard>
    </>
  )
}

export default DetailedProjectPage
